import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

export default function Subscription() {
  //SEO
  const seoTitle = { ru: "Подписка", fi: "Tilaus" }
  const seoSlug = { current: "tilaus" }

  return (
    <Layout subscribe={false}>
      <Seo title={seoTitle} slug={seoSlug} />
      {/* Title */}
      <section className="section-header">
        <h1 className="section-title">
          <span className="russian" lang="ru">
            Подписаться
          </span>
          <span className="finnish" lang="fi">
            Tilaa
          </span>
        </h1>
      </section>
      <section className="section-form">
        <div id="mc_embed_shell">
          <link
            href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
            rel="stylesheet"
            type="text/css"
          />
          <div id="mc_embed_signup">
            <form
              action={`https://culturalist.us20.list-manage.com/subscribe/post?u=2f80dd298e3b5b8a046f41238&amp;id=1ec5e261d0&amp;v_id=4495&amp;f_id=00205be6f0`}
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
            >
              <div className="form-subscribe">
                <div className="form-section-fields">
                  <div id="mc_embed_signup_scroll">
                    <div className="mc-field-group">
                      <label htmlFor="mce-EMAIL" className="form-field-label">
                        <span className="russian" lang="ru">
                          Email
                        </span>
                        <span className="asterisk">*</span>
                      </label>
                      <input
                        type="email"
                        name="EMAIL"
                        className="required email"
                        id="mce-EMAIL"
                        required
                      />
                    </div>
                    <div className="mc-field-group">
                      <label htmlFor="mce-FNAME" className="form-field-label">
                        <span className="russian" lang="ru">
                          Имя
                        </span>
                        <span className="finnish" lang="fi">
                          Nimi
                        </span>
                      </label>
                      <input
                        type="text"
                        name="FNAME"
                        className=" text"
                        id="mce-FNAME"
                      />
                    </div>
                    <div className="mc-field-group">
                      <label htmlFor="mce-MMERGE2" className="form-field-label">
                        <span className="russian" lang="ru">
                          Город
                        </span>
                        <span className="finnish" lang="fi">
                          Kaupunki
                        </span>
                      </label>
                      <input
                        type="text"
                        name="MMERGE2"
                        className=" text"
                        id="mce-MMERGE2"
                      />
                    </div>
                    <fieldset
                      className="mc_fieldset gdprRequired mc-field-group"
                      name="interestgroup_field"
                    >
                      <label className="checkbox subfield" htmlFor="gdpr42212">
                        <input
                          type="checkbox"
                          id="gdpr_42212"
                          name="gdpr[42212]"
                          className="gdpr"
                          style={{ width: "auto", flexBasis: 40, flexGrow: 0 }}
                          value="Y"
                        />
                        <div className="form-text locale-text-block">
                          <span className="russian" lang="ru">
                            Я даю согласие на отправку мне электронных писем и
                            обработку персональных данных в соответствии с{" "}
                            <a
                              href="/CulturaSaatio_Tietosuojakaytanto_14022024.pdf"
                              target="_blank"
                              rel="noreferrer noopener nofollow"
                              className="mark-link"
                            >
                              политикой конфиденциальности
                            </a>
                          </span>
                          <span className="finnish" lang="fi">
                            Hyväksyn sähköpostien vastaanottamisen ja
                            henkilötietojeni käsittelyn{" "}
                            <a
                              href="/CulturaSaatio_Tietosuojakaytanto_14022024.pdf"
                              target="_blank"
                              rel="noreferrer noopener nofollow"
                              className="mark-link"
                            >
                              tietosuojakäytännön
                            </a>{" "}
                            mukaisesti
                          </span>
                        </div>
                      </label>
                    </fieldset>
                    <div id="mce-responses" className="clear">
                      <div
                        className="response"
                        id="mce-error-response"
                        style={{ display: "none" }}
                      ></div>
                      <div
                        className="response"
                        id="mce-success-response"
                        style={{ display: "none" }}
                      ></div>
                    </div>
                    <div
                      aria-hidden="true"
                      style={{ position: "absolute", left: "-5000px" }}
                    >
                      <input
                        type="text"
                        name="b_2f80dd298e3b5b8a046f41238_1ec5e261d0"
                        tabindex="-1"
                        value=""
                      />
                    </div>
                    <div className="clear">
                      <input
                        type="submit"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="button"
                        value="Подписаться / Tilaa"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-section-info">
                  <div className="indicates-required">
                    <span className="asterisk">*</span>
                    <span className="russian" lang="ru">
                      обязательные поля
                    </span>
                    <span className="finnish" lang="fi">
                      pakolliset kentät
                    </span>
                  </div>
                  <div
                    id="mergeRow-gdpr"
                    className="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group"
                  >
                    <div className="content__gdpr form-text">
                      <div className="russian" lang="ru">
                        <p>
                          Cultura-säätiö будет использовать информацию,
                          предоставленную вами в этой форме, для связи с вами, а
                          также для предоставления информационных материалов.
                        </p>
                        <p>
                          Вы можете отказаться от подписки в любое время, нажав
                          на соответствующую ссылку в наших электронных письмах.
                        </p>
                      </div>
                      <div className="finnish" lang="fi">
                        <p>
                          Cultura-säätiö käyttää tällä lomakkeella antamiasi
                          tietoja ottaakseen sinuun yhteyttä ja toimittaakseen
                          sinulle tiedotusmateriaalia.
                        </p>
                        <p>
                          Voit milloin tahansa peruuttaa tilauksen uutiskirjeen
                          lopussa olevasta linkistä.
                        </p>
                      </div>
                    </div>
                    {/* <div className="content__gdprLegal">
                      <p>
                        We use Mailchimp as our marketing platform. By clicking
                        below to subscribe, you acknowledge that your
                        information will be transferred to Mailchimp for
                        processing.{" "}
                        <a href="https://mailchimp.com/legal/terms">
                          Learn more
                        </a>{" "}
                        about Mailchimp's privacy practices.
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <script
            type="text/javascript"
            src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
          />
          <script type="text/javascript">
            {`(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='MMERGE2';ftypes[2]='text';`}
            {/*
             * Translated default messages for the $ validation plugin.
             * Locale: RU
             */}
            {`$.extend($.validator.messages, {
                required: "Это поле необходимо заполнить.",
                remote: "Пожалуйста, введите правильное значение.",
                email: "Пожалуйста, введите корректный адрес электронной почты.",
                url: "Пожалуйста, введите корректный URL.",
                date: "Пожалуйста, введите корректную дату.",
                dateISO: "Пожалуйста, введите корректную дату в формате ISO.",
                number: "Пожалуйста, введите число.",
                digits: "Пожалуйста, вводите только цифры.",
                creditcard: "Пожалуйста, введите правильный номер кредитной карты.",
                equalTo: "Пожалуйста, введите такое же значение ещё раз.",
                accept: "Пожалуйста, выберите файл с правильным расширением.",
                maxlength: $.validator.format("Пожалуйста, введите не больше {0} символов."),
                minlength: $.validator.format("Пожалуйста, введите не меньше {0} символов."),
                rangelength: $.validator.format("Пожалуйста, введите значение длиной от {0} до {1} символов."),
                range: $.validator.format("Пожалуйста, введите число от {0} до {1}."),
                max: $.validator.format("Пожалуйста, введите число, меньшее или равное {0}."),
                min: $.validator.format("Пожалуйста, введите число, большее или равное {0}.")
            });}(jQuery));var $mcj = jQuery.noConflict(true);`}
          </script>
        </div>
      </section>
    </Layout>
  )
}
